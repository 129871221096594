const preconteststate = -1;
const conteststate = 0;
const postconteststate = 1;

const contestStart = new Date("2022-07-25T04:00:00.000Z"); //
const contestEnd = new Date("2022-11-02T03:59:59.000Z");

export function getContestState(){

    // check qs state
    // const search = window.location.search.substring(1);
    let state = null;

    // if (search=='contest'){
    //     state = conteststate;
    //     document.cookie = "conteststate="+state;
    // } else if (search=='postcontest') {
    //     state = postconteststate;
    //     document.cookie = "conteststate="+state;
    // } else if (search=='precontest') {
    //     state = preconteststate;
    //     document.cookie = "conteststate="+state;
    // } else {
    //     // check cookied state
    //     const cookie = document.cookie;
    //     const csplit = cookie.split(';');
    //     csplit.forEach(function(cookiepair){
    //         const cookieval = cookiepair.split('=');
    //         if (cookieval[0].trim()=='conteststate'){
    //             state = cookieval[1];
    //         }
    //     })
    // }

    // check actual date.
    if (state===null){
        var today = window.reactInit.initdate; //new Date("2021/08/15 11:59:00"); // 
        // console.log('checkdate',today);
        if (today>contestEnd){
            state = postconteststate;
        } else if (today>=contestStart){
            state = conteststate;
        } else {
            state = preconteststate;
        }
    }

    return state;
}