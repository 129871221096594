import { LOCALES } from '../locales.js';

export default {
    [LOCALES.ENGLISH]: {

        //global
        'site.title' : 'The Caramilk Secret is back!',
        'close': 'Close',

        //header

        //nav
        'menu.item.home' : 'HOME',
        'menu.item.winner' : 'PAST WINNERS',
        'menu.item.1': 'ENTER CONTEST',
        'menu.item.2': 'PRIZE DETAILS',
        'menu.item.3': 'FAQ',
        'menu.item.4': 'RULES',
        'caramilk.key' : 'Caramilk key',


        //footer
        'footer.link1' : 'Rules',
        'footer.link2' : 'Privacy Policy',
        'footer.link3' : 'Terms & Conditions',
        'footer.link4' : 'Accessibility Policy',
        'footer.link5' : 'Contact Us',

        'legal' : '*No purchase necessary. Open to residents of Canada (age of majority). Starts July 25/22 (12:00:00 a.m. ET) and ends Nov 2/22 (11:59:59 p.m. ET).  3\u00A0Grand\u00A0Prizes available ($10,000 CAD each). 202 Daily Prizes available (2 per Day; $100 CAD each). Odds depend on number and timing of eligible entries. Skill-testing question required. Enter online and rules at ',
        'legal.link' : 'www.caramilk.ca.',
        
        // 2020 Winners page
        'winners.title' : 'MEET OUR PAST WINNERS!',
        'winners.subtitle' : 'CONGRATULATIONS JESSICA!',
        'winners.body' : 'A big congratulations to Jessica from Verdun Quebec who unlocked the secret and won the 2020 grand prize!',
        'winners.video' : 'https://www.youtube.com/embed/NQI3RV2Th-A',
        'winners.alt' : 'Jessica holding $100,000 cheque',
        'winners.subtitle2' : 'CONGRATULATIONS JOANNE!',
        'winners.body2' : 'A big congratulations to Joanne from Kitchener Ontario who unlocked the secret and won the 2021 grand prize!',
        'winners.video2' : 'https://www.youtube.com/embed/NQI3RV2Th-A',
        'winners.alt2' : 'Joanne holding $100,000 cheque',

        //homapge
        'homepage.unlock': 'YOU COULD UNLOCK THE',
            'homepage.unlock.italic': 'CARAMILK SECRET',
            'homepage.win' : 'AND',
        'homepage.oneofthree' : 'WIN<sup>*</sup> 1 <small>OF</small> 3',
        'homepage.prize': '<sup>$</sup>10,000 PRIZES',
        'homepage.pluscash': 'PLUS DAILY CASH PRIZES',
        'homepage.cta' : 'Scroll to learn more',
        'homepage.howtoenter' : 'HOW TO ENTER',
        'homepage.step1head' : 'STEP 1',
        'homepage.step1' : 'Buy specially-marked Caramilk product',
        'homepage.step2head' : 'STEP 2',
        'homepage.step2' : 'Look for PIN codes found inside your participating Caramilk package.',
        'homepage.step3head' : 'STEP 3',
        'homepage.step3' : 'Enter PIN codes at caramilk.ca',


        'enterContestText' : 'Enter your unique PIN code {br} found inside your participating {br} Caramilk package',
        'contestClosedHead' : 'CONTEST CLOSED',
        'contestClosedText' : 'Thanks for playing! The Caramilk “You Could Unlock the Secret & WIN 1 of 3 $10,000” contest has ended, but keep searching for the Secret!',
        'contestClosedLink' : 'https://howcaramilkismade.com/',
        'contestClosedCTA' : 'click here',

        'enterNow' : 'Enter Now!',

        //coming soon and home page
        'comingsoon' : 'Coming Soon!',
        'unlock' : 'YOU COULD UNLOCK THE',
        'secret' : 'CARAMILK SECRET',
        'and' : "AND",
        'contest-start' : 'Look for participating Caramilk products.{br}Contest starts <em>July 25, 2022</em>',
        'view2020winner' : 'View Past Winners',
        'packlabel' : '',

        //enter contest page
        'step1': 'Step 1',
        'enterPin': 'ENTER PIN CODE',
        'enterKeyDescription': 'Enter the PIN code found inside your Caramilk wrapper to enter and see if you are a daily cash winner plus be entered into our Grand Prize Sweepstakes.',
        'popupBodytext' : 'Unique 12-digit PIN codes can be found behind the front panel of specially-marked individual Caramilk 50g, Caramilk Salted Caramel 50g, Caramilk 100g, Caramilk Salted Caramel 100g',
        'pin': 'PIN CODE',
        'nextStep': 'Next Step',

        'step2': 'Step 2',
        'enterInfo': 'ENTRY INFORMATION',
        'enterInfoDescription': 'Provide your contest entry information to complete your entry and see if you are a daily cash winner plus be entered into our Grand Prize Sweepstakes.',
        'firstName': 'First Name',
        'lastName': 'Last Name',
        'emailAddress': 'Email Address',
        'infoForm.age': 'I confirm that I am of the age of majority in the province/territory where I reside*',
        'infoForm.rules' : 'I have read and confirm compliance with the Mondelēz',
            'infoForm.rules.link1' : 'rules and regulations',
            'infoForm.rules2' : ' and the ',
            'infoForm.rules.link2' : 'Mondelēz Canada Privacy Policy*',
            'infoForm.rules3' : ' ',
        'infoForm.optin' : 'I confirm that I would like to receive updates on future Mondelēz Canada promotions (optional)',
        'requiredFields' : '*Required fields',
        'submit': 'Submit',



        //winning page
        'congratulation' : 'YOU HAVE BEEN SELECTED AS A POTENTIAL WINNER OF A DAILY $100\u00A0CASH\u00A0PRIZE!',
        'winningPage.body' : 'Before being declared an official winner, the holder of a winning PIN code must correctly answer, unaided, the mathematical skill-testing question below.',
        'winningPage.question' : '(5X5) – 21, then add 6 =',
        'winningPage.answer' : "your answer",
        'winningPage.cta' : 'SUBMIT',
        'winningPage.legal' : 'Please retain your PIN code /wrapper as the Contest Sponsor may require production of the winning PIN code before awarding a prize.',

        'winningPage.heading1' : 'CONGRATULATIONS!{br}YOU’RE A WINNER OF A DAILY $100\u00A0CASH\u00A0PRIZE!',
        'winningPage.body2' : 'You will receive the $100 prize via e-transfer to the email provided within 2-3 weeks.{br}{br}If you do not receive the e-transfer email within 2-3 weeks, please check your junk mailbox or contact us at ',
        'winningPage.body2a' : '.{br}{br}Plus you’ve been entered into the next draw for the Grand Prize!',

        'winningPage.heading3' : 'SORRY, INCORRECT ANSWER.',
        'winningPage.body3' : 'Please Try Again with another PIN code',

        'winningPage.heading2' : 'SHARE THE NEWS',
        'download': 'Download',


        //losing page
        'tryAgain' : 'THANKS FOR ENTERING THE CONTEST',
        'tryAgain.body' : 'You have successfully been entered into the next draw to WIN* 1 of 3 $10,000 PRIZES.{br}{br}You are not a daily winner but there are still daily cash prizes to be won.',
        'tryAgain.body.pin' : 'Enter another PIN code found inside specially-marked Caramilk packs for another chance to win.*',
        'tryAgain.btn' : 'Try Again', 
        'tryAgain.image1' : 'Caramilk 50 g bar',
        'tryAgain.image2' : 'Caramilk multipack',
        'tryAgain.heading2' : 'SHARE THE FUN',
        'tryAgain.body2' : 'Don’t keep it a secret! Share the contest with your friends and let them know there are still prizes to be won!',
        'tryAgain.body3' : 'Want to share to Instagram? Click the download button below and receive an image you can upload to your Instagram account.',

        //Prize Details
        'prizeDetails' : 'PRIZE DETAILS',

        'prizeDetails-heading1' : 'GRAND PRIZE',
        'prizeDetails-subheading1' : '1 of 3 cash prizes of $10,000',
        'prizeDetails-body1' : 'Draw dates:<li>September 1, 2022</li><li>October 1, 2022</li><li>November 3, 2022</li>',

        'prizeDetails-heading2' : '100s OF DAILY CASH PRIZES TO BE WON!',
        'prizeDetails-body2' : '2 per day!{br}{br}Each verified Daily Cash Prize winner will receive the Cash Prize of $100 plus still be eligible for a chance at winning 1 of 3 $10,000 grand prizes.',



        //faq page
        'faq-heading-text1': 'While the ',
            'faq-heading-link': 'Official Rules',
        'faq-heading-text2': ' outline the complete details of this promotion, your questions may already be answered below.',

        'q1': 'Q. What is the date period for this promotion?',
        'a1': 'A. Caramilk Unlock the Secret (the “Promotion”) begins on July 25, 2022 at 12:00:00 a.m. Eastern Time (“ET”) and ends on November 2, 2022 at 11:59:59 p.m. ET.',
        
        'q2': 'Q. What are the prizes for this promotion?',
        'a2': 'A. There are three (3) Grand Prizes of $10,000 available to be won plus two hundred and two (202) Daily Prizes of $100. Each confirmed winner will receive a cash prize of $100 and be entered into the draw for a chance to win one of three (1 of 3) Grand Prizes of $10,000.',
        
        'q2-b' : 'Q. How do I enter without purchasing a product?',
        'a2-b' : 'A. <b><u>No Purchase Necessary</u></b>: To obtain one (1) Pin Code, while supplies last, without purchasing an Eligible Product – print your first name, last name, telephone number, complete mailing address (including postal code) and age on a plain white piece of paper and mail it (in an envelope with sufficient Canadian postage) along with: (i) a Fifty (50) word or more unique and original sentence on HOW DO YOU THINK THE CARAMEL GETS INTO THE  CARAMILK BAR?; <u>and</u> (ii) a self-addressed pre-paid stamped envelope with sufficient return Canadian postage to the following address: CARAMILK SECRET CONTEST, 6 – 6150 Highway 7, Suite #154, Woodbridge, ON L4H 0R6 (each a “<b>Request</b>”). Upon receipt of a valid Request in accordance with these Official Rules, Administrator will mail you one (1) randomly selected Pin Code, while supplies last, per unique and original Request per envelope with sufficient Canadian postage.  To be eligible, any Request must: (i) be received separately in a single envelope bearing sufficient Canadian postage (i.e. multiple Requests in the same envelope will be void); and (ii) be post-marked during the Promotion Period and received by no later than October 7, 2022 to allow Sponsor sufficient time to mail back the Pin Code via regular mail. See full ',
            'a2-b.link': 'contest rules',
        'a2-b-bottom' : ' for details.',
        
        'q3': 'Q. How many times can I enter a PIN code?',
        'a3': 'A. Each PIN code is valid only for a single entry.',
        
        'q4': 'Q. How many prizes could I win? ',
        'a4': 'A.  There is a limit of one (1) daily prize and 1 grand Prize per person.',

        'q5': 'Q. I’m having trouble entering a PIN code. What should I do? ',
        'a5': 'A. Make sure you are entering the 12-digit alpha PIN code exactly as shown. Be sure you’re using a qualifying, specially-marked package of:',
            'a5-li1': 'Caramilk 50g',
            'a5-li2': 'Caramilk Salted Caramel 50g',
            'a5-li3': 'Caramilk 100g',
            'a5-li4': 'Caramilk Salted Caramel 100g',
        'a5-bottom': 'The PIN code will be printed on the inside of the individual Caramilk 50g, Caramilk Salted Caramel 50g, Caramilk 100g, Caramilk Salted Caramel 100g.  Please enter the PIN code without any dashes or spaces.',

        'q6': 'Q. I have a valid PIN code, but I’m getting an error.',
        'a6': 'A. Please enter the 12-digit alpha PIN code without any dashes or spaces. If it’s still not working, we’re here to help! Please contact us at ',
        
        'q7': 'Q. I received a message that I’ve already entered today, but I haven’t. What should I do?',
        'a7': 'A. Make sure you are visiting ',
            'a7-link': 'www.caramilk.ca',
        'a7-bottom': ' directly rather than a bookmarked page in your browser. Also, please try to clear cookies and cache on your internet browser.',

        'q8': 'Q. How will I know if I’m a potential Daily Cash Winner?',
        'a8': 'A. Two hundred and two (202) random, computer-generated times, each associated with one (1) of the Daily Win Prizes, will be generated during the Contest Period. If an entrant submits an Eligible Entry at the exact time of one (1) of the random, computer-generated times, or is the first entrant to submit an Eligible Entry after such time, he/she will be instantly notified. The potential winners will be required to correctly answer a skill-tesing question to be declared an official winner. Official Winners will be contacted via the contact information provided upon registration with further details about how to claim their Prize.{br}{br}Non Daily winners will still be entered for a chance at winning 1 of 3 grand prizes of $10,000 at the close of contest, November 2nd, 2022, 11:59 pm. The potential winners will be contacted via the contact information provided upon registration with further details about how to claim their Prize.',

        'q9': 'Q. I think I’m having technical issues. What should I do?',
        'a9': 'A. Sometimes it’s a simple thing like updating your browser. Check to be sure that you have a modern version of Chrome, Internet Explorer, Firefox, or Safari. Also, you may want to check your browser preferences section to be sure JavaScript is enabled. Clearing your cache and cookies also helps in this instance.',

        'q10': 'Q. I have recently moved or updated my email address. Can I change my registration information for this promotion?',
        'a10': 'A. We apologize for any inconvenience, but due to the number of entries we receive, we are unable to change registration information. If you are selected as a potential winner during the promotional period and your contact information does change, contact ',
        'a10-2' : ' and provide your updated information.  {br}{br} Please note: In the event that your contact information changes during the promotional period, we cannot guarantee that we will be able to accommodate your request.  {br}{br} Still have a question? Please contact us at ',
        'a10-3' : ' Please note: a response may take up to three business days.',

        'q11': 'Q. Why can’t I use my P.O. box address to register or claim my prize?',
        'a11': 'A. Your home address allows us to identify your resident for eligibility purposes.',

        'faq-footer-text': 'Still have a question? Please complete this form. Please note: a response may take up to three business days.',

        //Rules page
        'rules-page-heading': 'Official Rules',
    }
}